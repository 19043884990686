import { SxProps } from "@mui/material";

export const FormContainer: SxProps = {
  p: 2,
  width: "80vw",
  maxWidth: 500,
  display: "flex",
  flexDirection: "column",
  gap: 3,
};

export const AuthContainer: SxProps = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mb: "auto",
  mt: 2,
};

export const FormFields: SxProps = { gap: 2 };
